<template>
    <div class="construction">
        <div class="title">合规体系建设</div>
        <div class="construction-view">
            <div class="construction-item" v-for="(val, ind) in construction_list" :key="ind">
                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/constructionTag.png"
                    alt="">
                <div class="construction-item-title">{{val.title}}</div>
                <div class="construction-item-value">{{val.value}}</div>
                <div class="loos-dec cursor" @click="lookDetail(val.type)">查看详情>></div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            construction_list: [{
                title: "财税规划架构搭建",
                value: "通过为企业出具初步方案，优化企业基础框架，协助企业进行业务合理细分（财务管理方向）梳理各业务链条，设定相应的应用场景等服务让企业达到优化财税结构，降低系统性风险的效果。",
                type: 7,
            }, {
                title: "财务基础建设",
                value: "从制度上和流程上完善企业内部控制及风险管控体系；排查企业账务现状，做出风险提示及调整建议帮助财务部门从记账功能到财务管理功能的完善帮助企业合理发放薪酬，规避个税风险及用工风险。",
                type: 5,
            }, {
                title: "预算管理体系建设",
                value: "各层级及各阶段目标明确、提高员工主观能动力，提升企业资源转化率、提高预算执行力，规范、保障预算有效执行   、使企业全员重视预算，并行形成规范的预算管理制度，各部门能合理填制预算具备基本操作执行能力。",
                type: 8,
            }, {
                title: "财务分析体系建设",
                value: "通过数据可视化技术，帮助高层管理者全面掌控企业的财务状况以及相关财务数据，实现业财联动，更精准的做出科学决策，从而把握正确的企业发展方向，实现经济效益最大化。",
                type: 4,
            }, {
                title: "内控服务",
                value: "【现金与银行存款（即报销与费用支付）、销售-收款、采购-付款、存货-成本、人事-工资发放、税金-申报等六项中任选两项】流程梳理诊断，排查关键节点执行情况及潜在风险；",
                type: 3,
            }, {
                title: "财务报表审计（年报审计）",
                value: "出具审计报告，主要包括审计后的资产负债表、利润表、现金流量表；展示调整分录并说明调整原因及后续规范操作方法：给出管理建议：对审计中发现的管理问题予以提示并给出建议；",
                type: 6,
            }]
        }
    },
    methods: {
        lookDetail(type) {
            this.$router.push({
                path: "/serverDetail",
                query: {
                    type,
                },
            });
        },
    }
}
</script>
  
<style scoped lang="less">
.construction {
    width: 1300px;
    margin: 0 auto;
    padding: 58px 0 62px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 1;
    }

    .construction-view {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 13px;
    }

    .construction-item {
        width: 419px;
        height: 176px;
        background: #FFFFFF;
        border-radius: 10px;
        margin-top: 23px;
        position: relative;

        img {
            width: 76px;
            height: 36px;
            position: absolute;
            top: -3px;
            right: -6px;
        }

        .construction-item-title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            line-height: 1;
            padding-left: 29px;
            margin-top: 20px;
        }

        .construction-item-value {
            font-size: 14px;
            color: #999999;
            line-height: 20px;
            padding: 16px 29px 16px;
        }

        .loos-dec {
            font-size: 14px;
            color: #407BFB;
            position: absolute;
            right: 31px;
            bottom: 17px;
        }
    }
}
</style>