<template>
    <div class="chair">
        <div class="title">
            <div>专题讲座</div>
            <el-tooltip placement="right-start" popper-class="white-theme-edit">
                <div slot="content">
                    <div class="hint-view">
                        <div class="hint-title">专题讲座</div>
                        <div class="hint-value-1">
                            针对企业的经营现状，和专家团队一起研发相关系列课程，并且坚持每周四举办讲座（沙龙）建立一个可以帮助企业家学习如何合规经营管理的平台，提升企业治理能力。
                        </div>
                    </div>

                </div>
                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/setmealHint.png"
                    alt="">
            </el-tooltip>
        </div>
        <div class="nav-list">
            <div v-for="(val, ind) in nav_list" :key="ind">
                <div @click="sectNav(ind)" :class="`${ind === nav_sect ? 'nav-title-sect' : ''} nav-title`">{{val}}
                </div>
                <div v-show="ind === nav_sect" class="underline-css"></div>
            </div>
        </div>
        <div v-loading="loading">
            <div class="nav-second" v-if="nav_sect !== 0">
                <div>
                    <div @click="secondSect(val.id)"
                        :class="`${sect_id == val.id ? 'nav-second-item-sect' : ''} nav-second-item cursor`"
                        v-for="(val, ind) in nav_sect === 1 ? FINANCETAX_VIDEO : LAW_VIDEO" :key="ind">{{val.name_zh}}
                    </div>
                </div>
            </div>
            <div class="chair-view" :style="nav_sect === 0 ? 'margin-top: 63px;' : 'margin-top: 53px;'">
                <template v-if="nav_sect === 0">
                    <div @click="lookQRCode(val)" class="chair-item cursor" v-for="(val, ind) in info_list" :key="ind">
                        <el-image class="chair-item-img" :src="val.cover" fit="contain"></el-image>
                        <div class="chair-item-value">{{val.title}}</div>
                    </div>
                </template>
                <template v-else>
                    <div class="video-item" v-for="(val, ind) in info_list" :key="ind">
                        <div class="mask">
                            <i class="el-icon-video-play cursor" @click="handleContent(val)"></i>
                        </div>
                        <el-image style="width: 100%; height: 100%" :src="val.cover_url" fit="cover"></el-image>
                    </div>
                </template>
                <noDataImg v-if="info_list.length == 0 && !loading"></noDataImg>
            </div>
            <div class="pagination" v-if="info_list.length">
                <el-pagination layout="prev, pager, next" :total="count" background :current-page.sync="start"
                    :page-size="8" :hide-on-single-page="true" @current-change="currentChange" />
            </div>
        </div>
        <videoPlay ref="vidoePlay"></videoPlay>
        <el-dialog :visible.sync="dialogVisibleToQRcode" width="418px" :close-on-click-modal="false">
            <div class="code-css" v-loading="qrCodeLoading">
                <i class="el-icon-close code-close cursor" @click="codeClose"></i>
                <div class="code-css-img">
                    <img v-if="QrcodeURL" :src="QrcodeURL" alt="">
                </div>
                <div class="code-hint">扫描二维码，进入参展吧小程序观看视频</div>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import videoPlay from "@/baseComponents/videoPlay";
export default {
    components: { videoPlay },
    data() {
        return {
            params: {},
            start: 1,
            count: 0,
            nav_list: ["线上直播", "财税短视频", "法律短视频"],
            nav_sect: 0,
            FINANCETAX_VIDEO: [], //财务二级分类
            LAW_VIDEO: [], //法律二级分类
            sect_id: "",
            info_list: [],
            loading: false,
            QrcodeURL: "",
            dialogVisibleToQRcode: false, // 二维码弹窗
            qrCodeLoading: false,
        }
    },
    mounted() {
        this.params = { rtype: 9 };
        Promise.all([this.getEnums("FINANCETAX_VIDEO"), this.getEnums("LAW_VIDEO"), this.getList()]);
    },
    methods: {
        sectNav(index) {
            if (index !== this.nav_sect) {
                this.nav_sect = index;
                this.count = 0;
                this.start = 1;
                if (this.nav_sect === 0) {
                    this.params = { rtype: 9 };
                } else if (this.nav_sect === 1) {
                    this.sect_id = `${this.FINANCETAX_VIDEO.length ? this.FINANCETAX_VIDEO[0].id : ''}`;
                    this.params = {
                        rtype: 10,
                        extend_ids: this.sect_id,
                    };
                } else if (this.nav_sect === 2) {
                    this.sect_id = `${this.LAW_VIDEO.length ? this.LAW_VIDEO[0].id : ''}`;
                    this.params = {
                        rtype: 10,
                        extend_ids: this.sect_id,
                    };
                }
                this.getList();
            }
        },
        // 二级选择
        secondSect(id) {
            if (id !== this.sect_id) {
                this.sect_id = `${id}`;
                this.count = 0;
                this.start = 1;
                this.params.extend_ids = this.sect_id;
                this.getList();
            }
        },
        // 分页器
        currentChange(index) {
            this.start = index;
            this.getList();
        },
        async getList() {
            this.loading = true;
            let res = await this.$store.dispatch(
                "baseStore/getCompanyServiceVideo",
                {
                    limit: 8,
                    start: this.start - 1,
                    ...this.params
                }
            );
            if (res.success) {
                this.info_list = res.data;
                this.count = res.count;
            }
            this.loading = false;
        },
        async getEnums(code) {
            let res = await this.$store.dispatch(
                "baseStore/getCompanyEnums",
                { code }
            );
            if (res.success) {
                let ids = res.data && res.data.length ? res.data.map(item => item.id) : [];
                this[code] = [{
                    name_zh: "全部",
                    id: ids.toString(),
                },...res.data];
            }
        },
        handleContent(item) {
            this.$log({
                topic: 'comService',
                id: 'comService.clickVideo',
                content: JSON.stringify({ video_id: item.id })
            })
            if (!this.IS_LOGIN) {
                return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
            }
            this.$refs.vidoePlay.startPlay(item.aliyun_id);
        },
        async lookQRCode(value) {
            this.$log({
                topic: 'comService',
                id: 'comService.clickLive',
                content: JSON.stringify({ live_id: value.id })
            })
            if (!this.IS_LOGIN) {
                return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
            }
            this.QrcodeURL = "";
            this.dialogVisibleToQRcode = true;
            this.qrCodeLoading = true;
            let res = await this.$store.dispatch("baseStore/getAppletQRCode", {
                platform: 1,
                tenant_id: 1,
                page: "pages/initPage/initPage",
                width: 150,
                scene: `NexhibitionDetail&${value.id}&${value.atype}`,
                env_version: "release",
            });
            if (res.success) {
                this.QrcodeURL = res.file;
                this.qrCodeLoading = false;
            }
        },
        codeClose() {
            this.dialogVisibleToQRcode = false;
        },
    }
}
</script>
<style>
.white-theme-edit .popper__arrow {
    display: none;
}

.white-theme-edit {
    background: #FFFFFF !important;
    box-shadow: 0px 0px 8px 1px rgba(153, 153, 153, 0.2);
    padding: 10px 20px 16px;
}

.hint-view {
    width: 358px;
    overflow: hidden;

}

.hint-title {
    font-size: 16px !important;
    color: #333333;
    line-height: 1;
}

.hint-value-1 {
    font-size: 14px;
    color: #333333;
    line-height: 20px;
}

.hint-value-1 {
    margin-top: 12px;
}
</style>
<style scoped lang="less">
.chair {
    width: 1300px;
    margin: 0 auto;
    padding: 65px 0 18px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 22px;
            height: 22px;
            margin-left: 8px;
        }
    }

    .nav-list {
        width: 570px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-top: 48px;

        .nav-title {
            font-size: 16px;
            color: #999999;
            line-height: 1;
            cursor: pointer;
        }

        .nav-title-sect {
            color: #333333;
            font-weight: bold;
        }

        .underline-css {
            width: 58px;
            height: 8px;
            background: #4B86F2;
            border-radius: 4px;
            margin: 0 auto;
            margin-top: 12px;
        }
    }

    .nav-second {
        display: flex;
        justify-content: center;
        padding: 0 10px;
        margin-top: 38px;

        >div {
            display: flex;
            flex-wrap: wrap;
        }

        .nav-second-item {
            padding: 0 42px;
            height: 40px;
            line-height: 40px;
            background: #DDDDDD;
            border-radius: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #999999;
            margin-right: 40px;
            margin-bottom: 10px;

            &:last-child {
                margin-right: 0;
            }
        }

        .nav-second-item-sect {
            background: #4B86F2;
            color: #FFFFFF;
        }
    }

    .chair-view {
        min-height: 150px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    .chair-item {
        width: 314.5px;
        height: 180px;
        background: #FFFFFF;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        margin-right: 14px;
        margin-bottom: 10px;

        &:nth-child(4n) {
            margin-right: 0;
        }

        .chair-item-img {
            width: 315px;
            height: 180px;
        }

        .chair-item-value {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 36px;
            background: rgba(0, 0, 0, 0.39);
            font-size: 14px;
            color: #FFFFFF;
            padding: 0 10px;
            line-height: 36px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .pagination {
        margin: 20px auto 0;
        display: flex;
        flex-direction: row-reverse;
    }

    .video-item {
        width: 314.5px;
        height: 380px;
        position: relative;
        border-radius: 10px;
        margin-right: 14px;
        margin-bottom: 10px;
        overflow: hidden;

        &:nth-child(4n) {
            margin-right: 0;
        }
    }

    .mask {
        position: absolute;
        background: #000;
        opacity: 0.5;
        z-index: 100;
        width: 100%;
        height: 100%;
        border-radius: 10px;

        i {
            color: #fff;
            font-size: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            opacity: 0.7;
        }
    }

    .code-css {
        position: relative;
        width: 418px;
        height: 427px;
        overflow: hidden;

        .code-css-img {
            width: 190px;
            height: 190px;
            margin: 0 auto;
            margin-top: 98px;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        .code-close {
            font-size: 22px;
            position: absolute;
            font-weight: bold;
            color: #999999;
            top: 18px;
            right: 19px;
        }

        .code-hint {
            font-size: 18px;
            font-weight: 500;
            color: #999999;
            line-height: 1;
            margin-top: 64px;
            text-align: center;
        }
    }

    /deep/.el-dialog__header {
        display: none;
    }

    /deep/.el-dialog__body {
        width: 418px;
        height: 427px;
        padding: 0;
        background: #FFFFFF;
        box-shadow: 0px 3px 9px 1px rgba(143, 143, 143, 0.14);
        border-radius: 10px;
    }

}
</style>