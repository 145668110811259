<template>
    <div class="home">
        <div class="bg-fff">
            <banner type="9" />
            <basics />
        </div>
        <construction />
        <div class="bg-fff">
            <feature />
            <screen />
            <serve />
            <setMeal />
            <chair />
            <cooperation />
        </div>
        <div class="contact-way">
            <el-tooltip placement="left-start" popper-class="white-phone-look">
                <div slot="content">
                    <div class="hint-view-phone">
                        <div class="hint-title-phone">联系我们</div>
                        <div class="hint-phone-value">189 9807 8978</div>
                        <!-- <div class="hint-phone-call">拨打电话</div> -->
                    </div>
                </div>
                <img class="phone"
                    src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/phone.png"
                    alt="">
            </el-tooltip>
            <el-tooltip placement="left-start" popper-class="white-wx-look">
                <div slot="content">
                    <div class="hint-view-wx">
                        <div class="hint-title-wx">微信咨询</div>
                        <img class="hint-img-wx"
                            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/digitalbusinessCard/companyService/icon45.jpeg"
                            alt="">
                        <div class="hint-txt-wx">扫描二维码，添加好友</div>
                    </div>
                </div>
                <img class="we-chat"
                    src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/weChat.png"
                    alt="">
            </el-tooltip>
        </div>
    </div>
</template>
<script>
import banner from "../compontents/banner";
import basics from "./components/basics";
import construction from "./components/construction";
import feature from "./components/feature";
import screen from "./components/screen";
import serve from "./components/serve";
import setMeal from "./components/setMeal";
import chair from "./components/chair";
import cooperation from "./components/cooperation";
export default {
    components: {
        basics,
        banner,
        construction,
        feature,
        screen,
        serve,
        setMeal,
        chair,
        cooperation,
    },
    data() {
        return {
            type: 1
        }
    },
    mounted() {
        this.$log({
            topic: 'comService',
            id: 'comService.enter',
        })
    }
}
</script>
<style>
.white-phone-look .popper__arrow,
.white-wx-look .popper__arrow {
    display: none;
}

.white-phone-look,
.white-wx-look {
    background: #FFFFFF !important;
    box-shadow: 0px 0px 8px 1px rgba(153, 153, 153, 0.2);
    padding: 0;
}

.hint-view-phone {
    width: 237px;
    height: 130px;
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
}

.hint-title-phone,
.hint-title-wx {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-top: 20px;
    line-height: 1;
}

.hint-phone-value {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    margin-top: 32px;
}

.hint-phone-call {
    font-size: 18px;
    font-weight: 500;
    color: #407BFB;
    margin-top: 28px;
}

.hint-view-wx {
    width: 237px;
    height: 231px;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
}

.hint-img-wx {
    width: 103px;
    height: 103px;
    margin-top: 24px;
}

.hint-txt-wx {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 1;
    margin-top: 20px;
}
</style>
<style scoped lang="less">
.home {
    .bg-fff {
        background: #fff;
    }

    .contact-way {
        position: fixed;
        right: 29px;
        top: 50vh;
        z-index: 999;

        .phone,
        .we-chat {
            width: 60px;
            height: 60px;
            display: block;
        }

        .we-chat {
            margin-top: 22px;
        }
    }
}
</style>