<template>
    <div class="cooperation">
        <div class="title">合作律所</div>
        <div class="cooperation-view">
            <el-image class="cooperation-view-img" v-for="(val, ind) in cooperation_list" :key="ind" :src="val" fit="contain"></el-image>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            cooperation_list: ["https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/cooperation1.png", "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/cooperation2.png", "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/cooperation3.png", "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/cooperation4.png", "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/cooperation7.png", "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/cooperation5.png", "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/cooperation6.png"]
        }
    }
}
</script>
  
<style scoped lang="less">
.cooperation {
    width: 1300px;
    margin: 0 auto;
    padding: 65px 0 26px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 1;
    }

    .cooperation-view {
        display: flex;
        flex-wrap: wrap;
        margin-top: 49px;
        .cooperation-view-img{
            // width: 228px; 
            // height: 82px; 
            margin-right: 88px;
            margin-bottom: 40px;
            &:nth-child(5n){
                margin-right: 0;
            }
        }
    }


}
</style>