<template>
    <div class="serve">
        <div class="title">法律产品及其服务</div>
        <div class="serve-view">
            <div class="serve-item" v-for="(val, ind) in serve_list" :key="ind">
                <div class="serve-item-title">
                    <img :style="val.img_style" :src="val.img" alt="">
                    <div>{{val.title}}</div>
                </div>
                <div class="serve-item-value">{{val.value}}</div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            serve_list: [{
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/serveCompany.png",
                img_style: "width: 28px;height: 27px;",
                title: "企业日常经营法律服务",
                value: "常年法律顾问（可定制）劳动用工、商事合同（流程规范治理）债券催收，知识产权。"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/serveStock.png",
                img_style: "width: 25px;height: 25px;",
                title: "股权架构法律服务",
                value: "股权投融资、股权激励/资产重组、收并购/股权架构的顶层设计。"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/serveOverseas.png",
                img_style: "width: 25px;height: 25px;",
                title: "海商海事法律服务",
                value: "货运物流/保险、海商海事/国际贸易、涉外商事诉讼与仲裁。"
            }]
        }
    }
}
</script>
  
<style scoped lang="less">
// 18
.serve {
    width: 1300px;
    margin: 0 auto;
    padding: 65px 0 38px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 1;
    }

    .serve-view {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 52px;
    }

    .serve-item {
        width: 419px;
        height: 105px;
        background: #FFFFFF;

        .serve-item-title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            line-height: 1;
            padding-left: 29px;
            display: flex;
            align-items: flex-end;

            img {
                margin-right: 20px;
            }
        }

        .serve-item-value {
            font-size: 14px;
            color: #999999;
            line-height: 20px;
            padding: 18px 56px 16px 29px;
        }
    }
}
</style>