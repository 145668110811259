<template>
    <div class="screen">
        <div class="title">财务分享筛选（风控宝）</div>
        <div class="screen-view">
            <div class="screen-item" v-for="(val, ind) in screen_list" :key="ind">
                <div class="screen-item-img">
                    <img :style="val.img_style" :src="val.img" alt="">
                </div>
                <div class="screen-item-title">{{val.title}}</div>
                <div class="screen-item-line"></div>
                <div class="screen-item-value">{{val.value}}</div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            screen_list: [{
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/screenDetection.png",
                img_style: "width: 50px;height: 44px;",
                title: "精准监测",
                value: "与税务系统风险预警指标同步，分析企业信用分享状况，防微杜渐精准识别风险，并给出应对提示。"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/screenReal.png",
                img_style: "width: 49px;height: 49px;",
                title: "实时同步",
                value: "底层预设1556个行业，数十万个税务系统风险预警指标，实时契合国际税务总局政策文件更新，实时同步预警指标。"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/screenCon.png",
                img_style: "width: 47px;height: 48px;",
                title: "合理建设",
                value: "多维度识别潜在风险及问题，实现对企业财税风险的动态化实时预测预警，并给出针对性建议和意见。"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/screenMoney.png",
                img_style: "width: 45px;height: 52px;",
                title: "赋税合理",
                value: "通过大数据筛查，检查企业税负率的合理性，对企业多缴税少缴税，不缴税的不同风险及政策合理化应对提出重点关注和建议。"
            }]
        }
    }
}
</script>
  
<style scoped lang="less">
.screen {
    width: 1300px;
    margin: 0 auto;
    padding: 65px 0 15px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 1;
    }

    .screen-view {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 48px;
    }

    .screen-item {
        width: 314px;
        height: 349px;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px 1px rgba(64, 123, 251, 0.08);
        border-radius: 10px;
        &:hover{
            box-shadow: 0px 0px 6px 4px rgba(64,123,251,0.09);
        }

        .screen-item-img {
            height: 52px;
            text-align: center;
            margin-top: 53px;
        }

        .screen-item-title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            line-height: 1;
            text-align: center;
            margin-top: 26px;
        }

        .screen-item-line {
            width: 54px;
            height: 4px;
            background: #1C41E7;
            border-radius: 2px;
            margin: 0 auto;
            margin-top: 18px;
        }

        .screen-item-value {
            font-size: 14px;
            color: #333333;
            line-height: 36px;
            margin-top: 17px;
            padding: 17px 40px 0 30px;
        }
    }
}
</style>