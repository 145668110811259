<template>
    <div class="basics">
        <div class="title">企业基础服务</div>
        <div class="basics-view">
            <div class="basics-item" v-for="(val, ind) in basics_list" :key="ind">
                <img :src="val.img" alt="">
                <div class="basics-item-title" :style="val.title_color">{{val.title}}</div>
                <div class="basics-item-value">{{val.value}}</div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            basics_list: [{
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/basicsUser.png",
                title: "注册、变更、注销",
                title_color: "color: #E04C4D;",
                value: "为企业注册、变更、注销，解放企业人力提高效率"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/basicsAisle.png",
                title: "银行开户绿色通道",
                title_color: "color: #6687ED;",
                value: "免租赁合同、免法人亲临、免一切费用周期短"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/basicsBill.png",
                title: "智慧管账",
                title_color: "color: #ECA037;",
                value: "即时查账，精准监测实时预警，内部管理审慎严谨"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/basicsPolicy.png",
                title: "政策申请/税收扶持",
                title_color: "color: #B566ED;",
                value: "高新企业、双软企业申请等提供保姆级服务，帮助企业进行政策申请/税收扶持"
            }]
        }
    }
}
</script>
  
<style scoped lang="less">
.basics {
    width: 1300px;
    margin: 0 auto;
    padding: 52px 0 46px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 1;
    }

    .basics-view {
        display: flex;
        justify-content: space-around;
        margin-top: 82px;
    }

    .basics-item {
        width: 190px;
        padding: 10px;
        text-align: center;

        img {
            width: 70px;
            height: 70px;
        }

        .basics-item-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1;
            margin-top: 32px;
        }

        .basics-item-value {
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            margin-top: 16px;
        }
    }
}
</style>