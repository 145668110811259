<template>
    <div class="setmeal">
        <div class="title">
            <div>货代行业专属法律服务套餐</div>
            <el-tooltip placement="right-start" popper-class="white-theme-edit">
                <div slot="content">
                    <div class="hint-view">
                        <div class="hint-title">套餐解读</div>
                        <div class="hint-value-1">1、协助企业在劳动人事，对外合同流转过程，只是产权保护等日常运营过程中的法律合规，该套餐除日常规范外，更注重的是流程中的程序合规。
                        </div>
                        <div class="hint-value-2">
                            2、根据货代行业的业务经营特点，协助企业在进出口运输货物的过程中遇到的各类风险，如扣货压单、贸易欺诈、应收账款、催收、海关处罚等风险做出提前防范。
                        </div>
                    </div>

                </div>
                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/setmealHint.png"
                    alt="">
            </el-tooltip>
        </div>
        <div class="setmeal-view">
            <div @click="lookDetail(val.type)" class="setmeal-item cursor" v-for="(val, ind) in setmeal_list" :key="ind"
                :style="`background-image: url('${val.img}');`">
                <div class="setmeal-item-title" :style="val.title_color">{{val.title}}</div>
                <div class="setmeal-item-value" :style="val.value_color">{{val.value}}</div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            setmeal_list: [{
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/setmealBg1.png",
                img_style: "width: 28px;height: 27px;",
                title: "14项法律服务+金牌律师+2项增值服务",
                title_color: "color: #6A6B79;",
                value: "根据货代货运行业特点，协助解决企业在进出口运输货物过程中遇到的各类法律风险。",
                value_color: "color: #7E819D;",
                type: 1,
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/setmealBg2.png",
                img_style: "width: 25px;height: 25px;",
                title: "22项法律服务+王牌律师+3项增值服务",
                title_color: "color: #83693A;",
                value: "结合海运、国际贸易、国际结算、保险等领域，由背景优秀和执业经验丰富的海商海事律师提供专项及日常法律实务服务。",
                value_color: "color: #A1844E;",
                type: 2,
            }]
        }
    },
    methods: {
        lookDetail(type) {
            this.$router.push({
                path: "/serverDetail",
                query: {
                    type,
                },
            });
        },
    }
}
</script>
<style>
.white-theme-edit .popper__arrow {
    display: none;
}

.white-theme-edit {
    background: #FFFFFF !important;
    box-shadow: 0px 0px 8px 1px rgba(153, 153, 153, 0.2);
    padding: 10px 20px 16px;
}

.hint-view {
    width: 358px;
    overflow: hidden;

}

.hint-title {
    font-size: 16px !important;
    color: #333333;
    line-height: 1;
}
.hint-value-1, .hint-value-2{
    font-size: 14px;
    color: #333333;
    line-height: 20px;
}
.hint-value-1{
    margin-top: 12px;
}
.hint-value-2{
    margin-top: 28px;
}
</style>
<style scoped lang="less">
.setmeal {
    width: 1300px;
    margin: 0 auto;
    padding: 65px 0 38px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 22px;
            height: 22px;
            margin-left: 8px;
        }
    }

    .setmeal-view {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 52px;
    }

    .setmeal-item {
        width: 634px;
        height: 289px;
        background: #FFFFFF;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .setmeal-item-title {
            font-size: 25px;
            font-weight: bold;
            line-height: 1;
            padding-left: 29px;
            margin-top: 69px;
        }

        .setmeal-item-value {
            font-size: 22px;
            line-height: 40px;
            padding: 28px 58px 0 29px;
        }
    }
}
</style>