<template>
    <div class="feature">
        <div class="title">智能管账-产品特点</div>
        <div class="feature-view">
            <div class="feature-item" v-for="(val, ind) in feature_list" :key="ind">
                <div class="feature-item-title">
                    <img :style="val.img_style" :src="val.img" alt="">
                    <div>{{val.title}}</div>
                </div>
                <div class="feature-item-value">{{val.value}}</div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            feature_list: [{
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/feature1.png",
                img_style: "width: 34px;height: 29px;",
                title: "精准检测",
                value: "使用金三金四官方数据库作为筛查基础，数十万的筛查指标，契合税务总局政策文件，防微杜渐，精准识别风险，给予客户双重安全保障。"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/feature2.png",
                img_style: "width: 45px;height: 29px;",
                title: "即时查账快",
                value: "企业专属账务查询密码，实现24小时随时查账。"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/feature3.png",
                img_style: "width: 43px;height: 29px;",
                title: "行业专业度高",
                value: "服务大量业内企业，熟知行业特点，为行业量身定制科目明细。"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/feature4.png",
                img_style: "width: 46px;height: 29px;",
                title: "人员业务水平高",
                value: "资深500强注会亲自操刀，针对行业设计记账标准，按照行业特性对往来账目进行明细管理，做到应收尽收，应付清楚明了。"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/feature5.png",
                img_style: "width: 44px;height: 29px;",
                title: "风险预警及时准确",
                value: "真实反应企业经营状况，出局风险评估报告，并由专家对财务数据进行精准解读。"
            }, {
                img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/feature6.png",
                img_style: "width: 45px;height: 29px;",
                title: "战略决策数据支持",
                value: "对于收入、成本、费用进行准确细分，为企业业务提供最真实财务数据支持，为企业战略决策提供方向性建议。"
            }]
        }
    }
}
</script>
  
<style scoped lang="less">
// 18
.feature {
    width: 1300px;
    margin: 0 auto;
    padding: 65px 0 2px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 1;
    }

    .feature-view {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 49px;
    }

    .feature-item {
        width: 419px;
        height: 130px;
        background: #FFFFFF;
        margin-top: 7px;

        .feature-item-title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            line-height: 1;
            padding-left: 29px;
            display: flex;
            align-items: flex-end;

            img {
                margin-right: 14px;
            }
        }

        .feature-item-value {
            font-size: 14px;
            color: #999999;
            line-height: 20px;
            padding: 18px 29px 16px;
        }
    }
}
</style>